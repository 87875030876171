import React from 'react';

import styled from 'styled-components';

import {
    plurid,
} from '@plurid/plurid-themes';

import logo from '../../assets/aveled-logo-invert.png';



// #region module
const StyledDashboard = styled.div`
    padding: 5rem;
    font-family: ${plurid.fontFamilySansSerif};
    text-align: center;

    img {
        width: 15rem;
        padding: 1rem;
    }

    h1 {
        font-size: 7rem;
        margin-top: 0;
        margin-bottom: 3rem;
    }

    h2 {
        font-size: 2.2rem;
    }

    a {
        text-decoration: none;
        color: ${plurid.colorTertiary};
    }

    a:hover {
        color: ${plurid.colorPrimary};
    }
`;


const Dashboard: React.FC<any> = (
    properties,
) => {
    return (
        <StyledDashboard>
            <div>
                <img
                    src={logo}
                    alt="aveled"
                />
            </div>

            <h1>
                aveled
            </h1>

            <h2>
                automate the fields
            </h2>

            <div>
                <a
                    href="https://github.com/aveled"
                    target="_blank"
                    rel="noreferrer"
                >
                    github
                </a>
            </div>
        </StyledDashboard>
    );
}
// #endregion module



// #region exports
export default Dashboard;
// #endregion exports
